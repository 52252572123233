import Home from './pages/Home/Home';
import './App.css';
// import { Routes, Route } from 'react-router-dom';


function App() {
  return (
    <div >
      <Home/>
      {/* <Routes>
        <Route path='/' element={ <Home/>}/>
        <Route path='/contact' element={ <Contact/>}/>

       
       
      </Routes> */}
     
    </div>
  );
}

export default App;
