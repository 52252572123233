import React, { useEffect, useRef, useState } from 'react'
import { FaAngleLeft } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";
import debounce from 'lodash.debounce'
import './Problem.css'

const Problem = () => {

    const problem = [
        {
            id: 1, title: 'Как долго длится диагностика устройства?', lable: 'Проведение диагностики занимает от 1 до 3 рабочих дней в зависимости от загруженности мастерской.'
        },
        {
            id: 2, title: 'Как долго длится ремонт?', lable: 'Срок ремонта зависит от сложности поломки и наличия необходимых запчастей. Обычно ремонт занимает от нескольких часов до нескольких дней.'
        },
        {
            id: 3, title: 'Как я могу узнать стоимость ремонта?', lable: 'Стоимость ремонта определяется после проведения диагностики. Мы сообщим вам стоимость до начала работ. Если стоимость ремонта будет меньше 1500 рублей мы не согласовываем ремонт.'
        },
        {
            id: 4, title: ' Как можно оплатить ремонт?', lable: 'Ремонт можно оплатить наличными или по QR-коду.'
        },
        {
            id: 5, title: 'Можно ли принести свою запчасть?', lable: 'Да, вы можете принести свою запчасть, если она подходит по техническим характеристикам. Однако мы не даём гарантию на работу ваших запчастей.'
        },
        {
            id: 6, title: 'Какие гарантии вы предоставляете?', lable: 'Мы предоставляем гарантию на выполненные нами работы. Срок гарантии зависит от типа ремонта. На комплектующие гарантия предоставляется от магазина.'
        },
        {
            id: 7, title: ' Какие документы я получу после ремонта?', lable: 'После завершения ремонта вы получите кассовый чек, гарантийный талон, акт выполненных работ по необходимости.'
        },

    ]

    // const [canScrollLeft, setCanScrollLeft] = useState(false);
    // const [canScrollRight, setCanScrollRight] = useState(false);

    // const listRef = useRef(null);

    // const checkForScrollPosition = () => {
    //     const { current } = listRef;
    //     if (current) {
    //         const { scrollLeft, scrollWidth, clientWidth } = current;
    //         setCanScrollLeft(scrollLeft > 0);
    //         setCanScrollRight(scrollLeft !== scrollWidth - clientWidth);
    //     }
    // };

    // const debounceCheckForScrollPosition = debounce(checkForScrollPosition, 100);

    // const scrollContainerBy = (distance) =>
    //     listRef.current?.scrollBy({ left: distance, behavior: "smooth" });

    // useEffect(() => {
    //     const { current } = listRef;
    //     checkForScrollPosition();
    //     current?.addEventListener("scroll", debounceCheckForScrollPosition);

    //     return () => {
    //         current?.removeEventListener("scroll", debounceCheckForScrollPosition);
    //         debounceCheckForScrollPosition.cancel();
    //     };
    // }, []);

    return (
        <section className='problem'>
            <div className='problem__header'>
                <div className='problem__header-line'></div>
                <h2 className='problem__header-title'>Часто задаваемые вопросы</h2>
                <div className='problem__header-line'></div>
            </div>
            
            <div className='problem__items' >
            <p className='problem__title problem__title_header'>Эти ответы помогут вам лучше понять процесс работы мастерской и получить необходимую информацию для комфорного взаимодействия.</p>
                {
                    problem.map(item => <div key={item.id} className='problem__scrollbar-item'>
                        <p className='problem__title'>{item.title}</p>
                        <p className='problem__lable'>{item.lable}</p>
                    </div>)
                }
            </div>
         
           

        </section>
    )
}

export default Problem